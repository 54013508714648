<template>
  <el-main class="login">
    <el-row type="flex" justify="center">
       
      <el-col :span="20" class="login_main">
        <el-col :span="16" class="login_left"></el-col>
        <el-col :span="8" class="login_right">
            <el-col :offset="2" class="login_title">登录您的PDF GO账号</el-col>
            <el-col class="test1"><br></el-col>
            <el-image class="weixin_img1" :src="wximg"></el-image>
        </el-col>
      </el-col>
    </el-row>
    
  </el-main>
</template>

<script>
export default {
  name: "mylogin",
  data() {
    return {
      wximg: require("@/assets/wx.jpg")
    }
  }
};
</script>

<style scoped>
.login {
  background: linear-gradient(100.03deg,#0d0521,#31137c 51.85%,#761796 99.06%);
  background-color: #f1f3fe;
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  /* width: 100%; */
  height: 800px;
  padding-top: 120px;
}
.login_main {
  border-radius: 10px;
  height: 550px;
  box-shadow: 1px 1px 5px rgb(180, 175, 175);
  height: 550px;
  background: rgba(133, 122, 122, 0.3);
}
.login_left{
  background: url("~@/assets/feature.png");
  height: 100%;
  background-repeat: no-repeat;
  background-size: 500px;
  background-position:center;
}
.login_right{
  border-radius: 10px;
  background: rgba(255, 255, 255);
  height: 100%;
  padding: 40px 32px 16px;
}
.login_title{
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    margin-top: 32px;
    margin-left: 20%;

}
.test1{
    text-align: center;
}
.weixin_img1{
  border-radius: 10px;
  height: 60%;
  width: 80%;
  margin-top: 20px;
  margin-left: 10%;
}
</style>